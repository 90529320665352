import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navIndex: 0,
  },
  getters: {
    navIndex: state => state.app.navIndex,
  },
  mutations: {
    navIndex: (state, navIndex) => {
      state.navIndex = navIndex
    },
  },
  actions: {
  },
  modules: {

  }
})
