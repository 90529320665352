import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue'),
  }, {
    path: '/product',
    name: 'product',
    component: () => import('../views/product.vue'),
  }, {
    path: '/option',
    name: 'option',
    component: () => import('../views/option.vue'),
  }, {
    path: '/case',
    name: 'case',
    component: () => import('../views/case.vue'),
  }, {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import('../views/cooperation.vue'),
  }, {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
