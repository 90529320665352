<template>
  <div id="app" ref="app" @scroll="handleScroll">
    <Nav
      class="nav"
      @handleSelect="handleSelect"
      @onTouch="onTouch"
      :router="router"
    >
    </Nav>
    <keep-alive exclude="case,news">
      <router-view
        style="margin-top: 90px"
        @handleSelect="handleSelect"
        @onTouch="onTouch"
      />
    </keep-alive>
    <Footer @handleSelect="handleSelect"></Footer>
    <el-backtop target="#app" ref="backtop"></el-backtop>
  </div>
</template>
<script>
import Nav from "./components/nav.vue";
import Footer from "./components/footer.vue";
export default {
  name: "App",
  components: { Nav, Footer },
  data() {
    return {
      activeIndex: "1",
      windowWidth: document.documentElement.clientWidth,
      router: null,
    };
  },
  mounted() {
    let _this = this;
    window.onresize = function () {
      _this.windowWidth = document.documentElement.clientWidth;
    };
  },
  watch: {
    windowWidth(newVal) {
      // console.log(newVal);
      this.windowWidth = newVal;
    },
  },
  methods: {
    handleSelect(val, bool) {
      this.router = val;
      if (!bool) {
        this.$refs.backtop.scrollToTop();
      }
    },
    handleScroll(val) {
      // console.log(val.srcElement.scrollTop);
    },
    onTouch(val) {
      console.log("app", document.documentElement.clientWidth);
      this.$refs.app.scrollTo({
        // top: val ? val : document.documentElement.clientWidth,
        top: val ? val : 2080,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #00001d;
  transition: 1s;
}
h2 {
  font-size: 32px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin: 0;
  padding: 24px;
}
.nav {
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 999;
}
img {
  -webkit-user-drag: none;
}
button {
  user-select: none;
}
</style>
