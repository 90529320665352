import Vue from 'vue'
import App from './App.vue'
import router from './router'

import store from './store'
import ElementUi from "element-ui";
import "./assets/text/index.scss";
import "element-ui/lib/theme-chalk/index.css";
Vue.config.productionTip = false
Vue.use(ElementUi)
import axios from 'axios';
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video //引入Video播放器
Vue.prototype.$http = axios;
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
