<template>
  <div class="nav">
    <div class="left">
      <h1 @click="handleSelect('/')">
        <img
          src="../assets//logo.png"
          alt="幻视科技官网"
          title="幻视科技官网"
        />
      </h1>
    </div>

    <div class="right">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#2b2e3f"
        text-color="#fff"
        active-text-color="rgba(66, 158, 255, 1)"
        menu-trigger="hover"
      >
        <el-menu-item index="/">首 页</el-menu-item>
        <el-submenu
          index="/product"
          popper-class="subMenu"
          :show-timeout="0"
          :hide-timeout="0"
        >
          <template slot="title">
            <span
              style="
                line-height: 90px;
                display: inline-block;
                text-align: center;
                padding-left: 20px;
              "
              @click="handleSelect('/product')"
              >产品中心</span
            >
          </template>
          <el-menu-item index="/product">数字孪生平台</el-menu-item>
          <el-menu-item index="/product-1">三维GIS平台</el-menu-item>
          <!-- <el-menu-item index="/product-2">垃圾分类回收运营平台</el-menu-item> -->
          <!-- <el-menu-item index="/product-1">数据中台</el-menu-item> -->
          <!-- <el-menu-item index="/product-2">物联平台</el-menu-item> -->

          <!-- <el-menu-item index="/product-4">数据共享交换平台</el-menu-item> -->
          <!-- <el-menu-item index="/product-5">业务支撑平台</el-menu-item> -->
        </el-submenu>
        <el-submenu
          index="/option"
          popper-class="subMenu"
          :show-timeout="0"
          :hide-timeout="0"
        >
          <template slot="title">
            <span
              style="
                line-height: 90px;
                display: inline-block;
                text-align: center;
                padding-left: 20px;
              "
              @click="handleSelect('/option')"
              >解决方案</span
            >
          </template>
          <el-menu-item index="/option">智慧城市</el-menu-item>
          <el-menu-item index="/option-1">智慧公安</el-menu-item>
          <el-menu-item index="/option-2">智慧园区</el-menu-item>
          <el-menu-item index="/option-3">智能制造</el-menu-item>
          <!-- <el-menu-item index="/option-4">智慧交通</el-menu-item> -->
          <el-menu-item index="/option-5">绿色电力</el-menu-item>
          <el-menu-item index="/option-6">能耗双碳</el-menu-item>
          <el-menu-item index="/option-7">水利水务</el-menu-item>
          <!-- <el-menu-item index="/option-8">智慧教育</el-menu-item> -->
          <el-menu-item index="/option-9">垃圾分类回收运营平台</el-menu-item>
        </el-submenu>
        <el-menu-item index="/case">经典案例</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item index="/cooperation">新闻咨询</el-menu-item>
      </el-menu>
      <i class="el-icon-phone"></i>
      0571-86016856
      <span class="goto" @click="gotos">立即咨询</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    router: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeIndex: "",
    };
  },
  watch: {
    router(val) {
      this.activeIndex = val;
    },
  },
  mounted() {
    console.log(this.$store.state.navIndex);
    if (this.$store.state.navIndex) {
      this.activeIndex =
        window.location.pathname + "-" + this.$store.state.navIndex;
    } else {
      this.activeIndex = window.location.pathname;
    }
  },
  methods: {
    handleSelect(val) {
      // console.log(val);
      // console.log(window.location);
      // if (val.includes("-")) return;
      this.activeIndex = val;
      this.$store.commit("navIndex", val.split("-")[1] || 0);
      this.$emit("handleSelect", val);
      if (
        this.$route.path == val.split("-")[0] ||
        val.split("-")[0] == window.location.pathname
      )
        return;
      this.$router.push({
        path: val.split("-")[0],
      });
    },
    gotos() {
      this.$emit("handleSelect", "/case", true);
      this.$router.push("/case");
      this.$emit("onTouch", 10000);
    },
  },
};
</script>

<style lang="scss" scoped>
// $fontSize: calc(15px / 1920px);
$fontSize: 17px;
.nav {
  font-size: $fontSize;
  display: flex;
  background-color: #2b2e3f;
  // padding: 0 360px;
  justify-content: space-around;
  align-items: center;
  .left {
    h1 {
      cursor: pointer;
      margin: 0;
      display: flex;
      align-items: center;
    }
    img {
      width: 66px;
      height: 60px;
    }
  }
  .el-menu.el-menu--horizontal {
    border: 0;
    .el-menu-item {
      //   margin: 0 20px;
      font-size: $fontSize;
    }
  }
  .right {
    display: flex;
    align-items: center;
    color: #fff;
    .el-menu-demo {
      user-select: none;
    }
    .el-icon-phone {
      margin: 0 8px;
    }
    .goto {
      user-select: none;
      display: inline-block;
      height: 30px;
      font-size: 15px;
      border-radius: 20px;
      line-height: 30px;
      width: 100px;
      text-align: center;
      cursor: pointer;
      margin: 0 20px;
      background: linear-gradient(
        90deg,
        rgba(25, 87, 194, 0.8) 0%,
        rgba(82, 142, 247, 0.82) 47.12%,
        rgba(29, 95, 209, 0.81) 100%
      );
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.19);
    }
    &::v-deep .el-menu-demo > .el-menu-item,
    &::v-deep .el-submenu__title {
      font-size: $fontSize;
      height: 90px;
      line-height: 90px;
      width: 130px;
    }
  }
  &::v-deep .el-submenu__title {
    padding: 0;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      flex: 1;
      text-align: right;
    }
    i {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
}
</style>
<style lang="scss">
.subMenu {
  .el-menu {
    min-width: 116px;
    padding: 10px;
    .el-menu-item {
      font-size: 18px;
      line-height: 18px;
    }
  }
}
</style>