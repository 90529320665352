<template>
  <div class="footer">
    <div class="left">
      <div class="text">
        <h4>关于我们</h4>
        <p @click="to('/about')">公司简介</p>
        <!-- <p>组织架构</p>
        <p>现任领导</p>
        <p>品牌经典</p> -->
      </div>
      <div class="text">
        <h4>产品中心</h4>
        <p @click="to('/product')">数字孪生平台</p>
        <!-- <p @click="to()">数据中台</p>
        <p @click="to()">物联平台</p> -->
        <p @click="to('/product-1')">三维GIS平台</p>
        <!-- <p @click="to()">数据共享交换平台</p>
        <p @click="to()">业务支撑平台</p> -->
      </div>
      <div class="text">
        <h4>标杆案例</h4>
        <p @click="to('/case?val=1')">智慧园区</p>
        <p @click="to('/case?val=2')">智慧校园</p>
        <p @click="to('/case?val=3')">智慧文旅</p>
        <p @click="to('/case?val=4')">智慧城市</p>
        <p @click="to('/case?val=5')">智能能源</p>
        <p @click="to('/case?val=6')">智慧建筑</p>
        <p @click="to('/case?val=7')">智慧交通</p>
      </div>
      <div class="text">
        <h4>解决方案</h4>
        <p @click="to('/option')">智慧城市</p>
        <p @click="to('/option-1')">智慧公安</p>
        <p @click="to('/option-2')">智慧园区</p>
        <p @click="to('/option-3')">智能制造</p>
        <p @click="to('/option-5')">绿色电力</p>
        <p @click="to('/option-6')">能耗双碳</p>
        <p @click="to('/option-7')">水利水务</p>
        <p @click="to('/option-9')">垃圾分类回收</p>
      </div>
    </div>
    <div class="right">
      <div class="text">
        <p><img src="../assets/footer/dianhua.png" alt="" />0571-86016856</p>
        <p><img src="../assets/footer/chuanzhen.png" alt="" />0571-86016856</p>
        <p>
          <img
            src="../assets/footer/dingwei.png"
            alt=""
          />杭州市拱墅区北部软件园A403
        </p>
      </div>

      <div class="qrcode">
        <div>
          <img src="../assets/footer/qrcode.png" alt="" />
        </div>

        <p>扫一扫关注我们</p>
      </div>
      <h2>数字孪生数智化服务商！</h2>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    to(val) {
      this.$store.commit("navIndex", val.split("-")[1] || 0);
      this.$emit("handleSelect", val);
      if (
        this.$route.path == val.split("-")[0] ||
        val.split("-")[0] == window.location.pathname
      )
        return;
      this.$router.push({
        path: val.split("-")[0],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  height: 470px;
  background: rgba(59, 59, 59, 1);
  // padding: 80px 360px;
  padding-top: 80px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  justify-content: space-around;
  h4,
  p {
    margin: 0;
    text-align: left;
  }
  .left {
    display: flex;
    .text {
      margin: 0 20px;
      h4 {
        margin: 24px 20px;
        font-size: 18px;
      }
      p {
        margin: 20px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.3);
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: rgb(61, 147, 240);
        }
      }
    }
  }
  .right {
    display: flex;
    position: relative;
    padding: 40px;
    padding-top: 0;
    .text {
      p {
        margin: 30px 20px;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 22px;
          margin: 0 20px;
        }
      }
    }

    .qrcode {
      // position: absolute;
      // right: 199px;
      // top: 112px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      margin: 30px 20px;
      margin-left: 40px;
      align-items: center;
      div {
        width: 97px;
        height: 97px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
      }
      img {
        width: 91px;
        height: 91px;
      }
      p {
        margin: 15px 0;
        color: rgba(246, 246, 246, 0.5);
      }
    }
    h2 {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 120px;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.56);
      /** 文本1 */
      font-size: 44px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 57.6px;
      color: rgba(255, 255, 255, 1);
      margin: 0;
      padding: 0;
    }
  }
}
</style>